import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SearchAPI from "../components/search/searchalter"
import SafeSpace from "../img/safety-badge.jpg"
import SEOHeader from "../components/seo-header"
import { graphql, Link } from "gatsby"
import VizSensor from "react-visibility-sensor"
import PreLoader from "../components/Card/preloader.png"
import Img from "gatsby-image"
import "lazysizes"
import {
  MeetingContent,
  MeetingContentBottom,
} from "../components/pagecontent/meetingcontent"
const MeetingSpacesCity = props => {
  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const { city } = props.pageContext
  const { displaycity } = props.pageContext
  const { slug } = props.pageContext
  const [spaceSize, setspaceSize] = useState("00")
  const [localityData, setlocalityData] = useState([])
  const lists = props.data.meetSpace.edges
  const listscoworking = props.data.coworkSpace.edges
  const locality = props.data.allLocalities.edges
  const meetingBanner = props.data.meeting.childImageSharp.fluid
  const filter = {
    keyword: city,
  }
  useEffect(() => {
    setspaceSize(lists.length)
  })
  const LocalityCount = props => {
    const localitynew = lists.filter(
      list => list.node.spaceAddress === props.name
    )
    return localitynew.length
  }
  const LocalityImage = name => {
    const localitynew = lists.filter(list => list.node.spaceCity === name)

    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }
  const coworkingLocalityImage = name => {
    const localitynew = listscoworking.filter(list => list.node.spaceCity === name)

    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }
  const LocalityCityImage = name => {
    const localitynew = lists.filter(list => list.node.spaceCity === name)

    if (localitynew.length > 0) {
      return localitynew[0].node.spaceImage
    }
    return ""
  }
  return (
    <div>
      <SEOHeader
        title={
          "  Meeting Rooms in " +
          city +
          " | Meeting Spaces in " +
          city +
          " | GoFloaters"
        }
        description={
          "Find fully equipped, safe and verified meeting rooms in " +
          city +
          " for your business meetings, presentations, training and interviews on GoFloaters."
        }
        socialURL={
          "https://assets.gofloaters.com/socialimage/meeting-rooms-in-" +
          slug +
          ".jpg"
        }
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <div>
          {/*<HeaderBanner headerclass="office-space"></HeaderBanner>*/}
          <div className="">
            <div>
              <section className="banner">
                <div className="container">
                  <div className="row aligner">
                    <h2 className="meetingspace mx-3 fw-bold">Meeting Spaces</h2>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="container">
          <br></br>
          <div className="row">
            {locality.map(Locality => {
              const localitynew = lists.filter(
                list => list.node.spaceAddress === Locality.node.localityName &&
                list.node.spaceCity === "Conference Room"
              )
              if (Locality.node.localitySort) {
                return (
                  <Link
                    to={
                      "/meeting-spaces/" +
                      Locality.node.cityslug +
                      "/all/?operatorName=" +
                      "iKSANA Workspaces"
                    }
                    className={"col-md-4 zero_" + localitynew.length}
                  >
                    <div className="localityBox">
                      <VizSensor
                        partialVisibility
                        onChange={isVisible => {
                          setimgViz(isVisible)
                          if (!imgVizdisplay) {
                            setimgVizdisplay(isVisible)
                          }
                        }}
                      >
                        {imgVizdisplay ? (
                          <div
                            className="localityimageBox"
                            style={{
                              backgroundImage:
                                "url(" +
                                LocalityImage(Locality.node.localityName) +
                                ")",
                            }}
                          ></div>
                        ) : (
                          <div
                            className="localityimageBox"
                            style={{
                              backgroundImage: PreLoader,
                            }}
                          ></div>
                        )}
                      </VizSensor>
                      <h4 className="localityboxText alignerRight">
                        <span>
                          {lists.length ? lists.length : "0"}{" "}
                          Meeting Spaces in <br></br>
                          {Locality.node.localityName}{" "}
                        </span>
                        <span style={{ paddingLeft: "10px" }}>
                          <i className="fa fa-arrow-circle-right"></i>
                        </span>
                      </h4>
                    </div>
                  </Link>
                )
              }
            })}
            {spaceSize !== 0 ? (
              <Link
                to={"/meeting-spaces/" + slug +  "/all/?operatorName=" +
                "iKSANA Workspaces"}
                className="col-md-4"
              >
                <div className="localityBox alter">
                  <VizSensor
                    partialVisibility
                    onChange={isVisible => {
                      setimgViz(isVisible)
                      if (!imgVizdisplay) {
                        setimgVizdisplay(isVisible)
                      }
                    }}
                  >
                    {imgVizdisplay ? (
                      <div
                        className="localityimageBox"
                        style={{
                          backgroundImage:
                            "url(" + LocalityCityImage(city) + ")",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="localityimageBox"
                        style={{
                          backgroundImage: PreLoader,
                        }}
                      ></div>
                    )}
                  </VizSensor>
                  <h4 className="localityboxText alignerRight">
                    <span>
                      {lists.length} Meeting Spaces in <br></br>
                      {city}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                  </h4>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="">
          <div>
            <section className="banner">
              <div className="container">
                <div className="row aligner">
                  <h2 className="meetingspace mx-3 fw-bold">CoWorking Day passes</h2>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="container">
          <br></br>
          <div className="row">
            {spaceSize !== 0 ? (
              <Link
                to={"/coworking-spaces/" + slug + "/all/?operatorName=" +
                "iKSANA Workspaces"}
                className="col-md-4"
              >
                <div className="localityBox alter">
                  <VizSensor
                    partialVisibility
                    onChange={isVisible => {
                      setimgViz(isVisible)
                      if (!imgVizdisplay) {
                        setimgVizdisplay(isVisible)
                      }
                    }}
                  >
                    {imgVizdisplay ? (
                      <div
                        className="localityimageBox"
                        style={{
                          backgroundImage:
                            "url(" + coworkingLocalityImage(city) + ")",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="localityimageBox"
                        style={{
                          backgroundImage: PreLoader,
                        }}
                      ></div>
                    )}
                  </VizSensor>
                  <h4 className="localityboxText aligner">
                    <span>
                      {listscoworking.length} Coworking Day passes <br></br>
                      {city}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                  </h4>
                </div>
              </Link>
            ) : (
              ""
            )}
            
          
          </div>
        </div>

        
     
      </Layout>
    </div>
  )
}
export default MeetingSpacesCity
export const query = graphql`
  query MeetingSpacesCity($city: String!) {
    meetSpace : allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        spaceCity: { eq: $city }
        operatorName: { regex: "/iKSANA Workspaces/" }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
        }
      }
    }
    coworkSpace : allListings(
        filter: {
          spaceType: { eq: "Shared Workspace" }
          spaceCity: { eq: $city }
          dayPassAvailable: { eq: true }
          slug: { ne: "" }
          online: { eq: true }
          operatorName: { regex: "/iKSANA Workspaces/" }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
        }
      }
    }
    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
    meeting: file(relativePath: { eq: "coworking/meeting-space.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
