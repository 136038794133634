import React, { useEffect, useState } from "react"
import Autocomplete from "react-google-autocomplete"
import { navigate } from "gatsby"

const SearchFormAlter = props => {
  var spacetype = props.spacetype
  const [gpsLocation, setgpsLocation] = useState(true)
  useEffect(() => {
    if ("geolocation" in navigator) {
      setgpsLocation(true)
    } else {
      setgpsLocation(false)
    }
  }, [])
  const doSomething = place => {
    let address = place.formatted_address,
      location = address.split(",")[0],
      city = address.split(",")[1],
      state = address.split(",")[2],
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng()
    location = location.trim()
    city = city.trim()
    var url =
      "/searchresult?city=" +
      location +
      ", " +
      city +
      ", " +
      state +
      "&lat=" +
      latValue +
      "&lng=" +
      lngValue +
      "&spaceType=" +
      spacetype
    navigate(url)
  }
  const getGEO = place => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setgpsLocation(true)
      var url =
        "/search?city=Nearby Spaces" +
        "&lat=" +
        position.coords.latitude +
        "&lng=" +
        position.coords.longitude +
        "&spaceType=" +
        spacetype
      navigate(url)
    })
  }
  return (
    <div>
      <div className="homePageSearch">
        <div className="input-group has-search">
          <span
            className="fa fa-search form-control-feedback"
            style={{ lineHeight: 2.3 }}
          ></span>
          <Autocomplete
            style={{ width: "90%" }}
            className="form-control signupform"
            placeholder={
              props.placeholder
                ? props.placeholder
                : "Where would you like to work out of?"
            }
            onPlaceSelected={place => {
              doSomething(place)
            }}
            /* onPlaceSelected={place => {
              let address = place.formatted_address,
                location = address.split(",")[0],
                city = address.split(",")[1],
                state = address.split(",")[2],
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng()
              location = location.trim()
              city = city.trim()
              var url =
                "/search/?city=" +
                location +
                ", " +
                city +
                ", " +
                state +
                "&lat=" +
                latValue +
                "&lng=" +
                lngValue +
                "&spaceType=dailyofficeSpace"
              navigate(url)
            }} */
            types={["establishment" | "cities"]}
            options={{
              componentRestrictions: { country: "in" },
            }}
          />
          {/* 
          <input
            className="form-control signupform"
            placeholder="Enter your location.."
          ></input> */}
        </div>
        {/*gpsLocation ? (
          <div>
            <p style={{ color: "#000" }}>&nbsp;or</p>
            <a onClick={getGEO} className="claimnowButton">
              NEARBY SPACE
            </a>
          </div>
        ) : (
          ""
        )*/}
      </div>
    </div>
  )
}
export default SearchFormAlter
